<template>
	<div class="pui-form">
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<logaiseventextended-form-header :modelPk="modelPk"></logaiseventextended-form-header>
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-layout wrap class="pui-form-layout">
				<v-flex xs12 md7>
					<pui-field-set :title="$t('form.logaiseventextended.maininfo')">
						<v-layout wrap>
							<!-- EVENTDATE -->
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<pui-date-field
											:id="`eventdate-logaiseventextended`"
											v-model="model.eventdate"
											:label="$t('form.logaiseventextended.eventdate')"
											:disabled="formDisabled"
											toplabel
											maxlength="32"
											time
										></pui-date-field>
									</v-flex>
								</v-layout>
							</v-flex>
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12> </v-flex>
								</v-layout>
							</v-flex>
							<!-- VESSELMMSI -->
							<v-flex xs12 md4>
								<v-layout wrap>
									<v-flex xs12>
										<pui-text-field
											:id="`vesselmmsi-logaiseventextended`"
											v-model="model.vesselmmsi"
											:label="$t('form.logaiseventextended.vesselmmsi')"
											:disabled="formDisabled"
											toplabel
											maxlength="200"
										></pui-text-field>
									</v-flex>
								</v-layout>
							</v-flex>
							<!-- VESSELIMO -->
							<v-flex xs12 md4>
								<v-layout wrap>
									<v-flex xs12>
										<pui-text-field
											:id="`vesselimo-logaiseventextended`"
											v-model="shipInfo"
											:label="$t('form.logaiseventextended.vesselimo')"
											:disabled="formDisabled"
											toplabel
											maxlength="200"
										></pui-text-field>
									</v-flex>
								</v-layout>
							</v-flex>
							<!-- VESSELCALLSIGN -->
							<v-flex xs12 md4>
								<v-layout wrap>
									<v-flex xs12>
										<pui-text-field
											:id="`vesselcallsign-logaiseventextended`"
											v-model="model.vesselcallsign"
											:label="$t('form.logaiseventextended.vesselcallsign')"
											:disabled="formDisabled"
											toplabel
											maxlength="200"
										></pui-text-field>
									</v-flex>
								</v-layout>
							</v-flex>
							<!-- BERTHINGNUMBER -->
							<!--<v-flex xs12 md3>
								<v-layout wrap>
									<v-flex xs12>
										<pui-number-field
											:id="`berthingnumber-logaiseventextended`"
											v-model="model.berthingnumber"
											:label="$t('form.logaiseventextended.berthingnumber')"
											:disabled="formDisabled"
											toplabel
											maxlength="10"
										></pui-number-field>
									</v-flex>
								</v-layout>
							</v-flex>-->
							<!-- GEOMID -->
							<v-flex xs12 md3>
								<v-layout wrap>
									<v-flex xs12>
										<pui-text-field
											:id="`geomid-logaiseventextended`"
											v-model="model.geomid"
											:label="$t('form.logaiseventextended.geomid')"
											:disabled="formDisabled"
											toplabel
											maxlength="50"
										></pui-text-field>
									</v-flex>
								</v-layout>
							</v-flex>
							<!-- GEOMDESC -->
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<pui-text-field
											:id="`geomdesc-logaiseventextended`"
											v-model="model.geomdesc"
											:label="$t('form.logaiseventextended.geomdesc')"
											:disabled="formDisabled"
											toplabel
											maxlength="200"
										></pui-text-field>
									</v-flex>
								</v-layout>
							</v-flex>
						</v-layout>
					</pui-field-set>
					<pui-field-set :title="$t('form.logaiseventextended.managementinfo')">
						<v-layout wrap>
							<!-- EVENTETA -->
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<pui-date-field
											:id="`eventeta-logaiseventextended`"
											v-model="model.eventeta"
											:label="$t('form.logaiseventextended.eventeta')"
											:disabled="formDisabled"
											toplabel
											maxlength="32"
											time
										></pui-date-field>
									</v-flex>
								</v-layout>
							</v-flex>
							<!-- EVENTETD -->
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<pui-date-field
											:id="`eventetd-logaiseventextended`"
											v-model="model.eventetd"
											:label="$t('form.logaiseventextended.eventetd')"
											:disabled="formDisabled"
											toplabel
											maxlength="32"
											time
										></pui-date-field>
									</v-flex>
								</v-layout>
							</v-flex>
							<!-- VESSELFLAG -->
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<!--<pui-text-field
											:id="`vesselflag-logaiseventextended`"
											v-model="model.vesselflag"
											:label="$t('form.logaiseventextended.vesselflag')"
											:disabled="formDisabled"
											toplabel
											maxlength="200"
										></pui-text-field>-->
										<pui-select
											id="model.vesselflag"
											attach="model.vesselflag"
											:label="$t('form.logaiseventextended.vesselflag')"
											toplabel
											clearable
											:disabled="formDisabled"
											v-model="model"
											modelName="country"
											:modelFormMapping="{ code: 'code' }"
											:itemsToSelect="[{ code: this.model.vesselflag }]"
											itemValue="code"
											itemText="name"
											reactive
										>
										</pui-select>
									</v-flex>
								</v-layout>
							</v-flex>
							<!-- VESSELLENGTH -->
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<pui-number-field
											:id="`vessellength-logaiseventextended`"
											v-model="model.vessellength"
											:label="$t('form.logaiseventextended.vessellength')"
											:disabled="formDisabled"
											toplabel
											maxlength="8"
										></pui-number-field>
									</v-flex>
								</v-layout>
							</v-flex>
							<!-- VESSELBEAM -->
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<pui-number-field
											:id="`vesselbeam-logaiseventextended`"
											v-model="shipInfo"
											:label="$t('form.logaiseventextended.vesselbeam')"
											:disabled="formDisabled"
											toplabel
											maxlength="8"
										></pui-number-field>
									</v-flex>
								</v-layout>
							</v-flex>
							<!-- VESSELDEPTH -->
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<pui-number-field
											:id="`vesseldepth-logaiseventextended`"
											v-model="model.vesseldepth"
											:label="$t('form.logaiseventextended.vesseldepth')"
											:disabled="formDisabled"
											toplabel
											maxlength="8"
										></pui-number-field>
									</v-flex>
								</v-layout>
							</v-flex>
							<!-- VESSELMAXDRAUGHT -->
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<pui-number-field
											:id="`vesselmaxdraught-logaiseventextended`"
											v-model="model.vesselmaxdraught"
											:label="$t('form.logaiseventextended.vesselmaxdraught')"
											:disabled="formDisabled"
											toplabel
											maxlength="8"
										></pui-number-field>
									</v-flex>
								</v-layout>
							</v-flex>
							<!-- VESSELGT -->
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<pui-number-field
											:id="`vesselgt-logaiseventextended`"
											v-model="model.vesselgt"
											:label="$t('form.logaiseventextended.vesselgt')"
											:disabled="formDisabled"
											toplabel
											maxlength="10"
										></pui-number-field>
									</v-flex>
								</v-layout>
							</v-flex>
							<!-- VESSELSHIPOWNER -->
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<pui-number-field
											:id="`vesselshipowner-logaiseventextended`"
											v-model="model.vesselshipowner"
											:label="$t('form.logaiseventextended.vesselshipowner')"
											:disabled="formDisabled"
											toplabel
											maxlength="10"
										></pui-number-field>
									</v-flex>
								</v-layout>
							</v-flex>
						</v-layout>
					</pui-field-set>
				</v-flex>
				<v-flex xs12 md5 v-if="!isCreatingElement">
					<pui-field-set :title="$t('form.logaiseventextended.aisinfo')">
						<v-layout wrap>
							<!-- AISSPEED -->
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<pui-number-field
											:id="`aisspeed-logaiseventextended`"
											v-model="model.aisspeed"
											:label="$t('form.logaiseventextended.aisspeed')"
											:disabled="formDisabled"
											toplabel
											maxlength="8"
										></pui-number-field>
									</v-flex>
								</v-layout>
							</v-flex>
							<!-- AISCOURSE -->
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<pui-number-field
											:id="`aiscourse-logaiseventextended`"
											v-model="model.aiscourse"
											:label="$t('form.logaiseventextended.aiscourse')"
											:disabled="formDisabled"
											toplabel
											maxlength="8"
										></pui-number-field>
									</v-flex>
								</v-layout>
							</v-flex>
							<!-- AISHEADING -->
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<pui-number-field
											:id="`aisheading-logaiseventextended`"
											v-model="model.aisheading"
											:label="$t('form.logaiseventextended.aisheading')"
											:disabled="formDisabled"
											toplabel
											maxlength="10"
										></pui-number-field>
									</v-flex>
								</v-layout>
							</v-flex>
							<!-- AISTURN -->
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<pui-number-field
											:id="`aisturn-logaiseventextended`"
											v-model="model.aisturn"
											:label="$t('form.logaiseventextended.aisturn')"
											:disabled="formDisabled"
											toplabel
											maxlength="10"
										></pui-number-field>
									</v-flex>
								</v-layout>
							</v-flex>
							<!-- AISLON -->
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<pui-number-field
											:id="`aislon-logaiseventextended`"
											v-model="model.aislon"
											:label="$t('form.logaiseventextended.aislon')"
											:disabled="formDisabled"
											toplabel
											maxlength="8"
										></pui-number-field>
									</v-flex>
								</v-layout>
							</v-flex>
							<!-- AISLAT -->
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<pui-number-field
											:id="`aislat-logaiseventextended`"
											v-model="model.aislat"
											:label="$t('form.logaiseventextended.aislat')"
											:disabled="formDisabled"
											toplabel
											maxlength="8"
										></pui-number-field>
									</v-flex>
								</v-layout>
							</v-flex>
							<!-- AISDESTINATION -->
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<pui-text-field
											:id="`aisdestination-logaiseventextended`"
											v-model="model.aisdestination"
											:label="$t('form.logaiseventextended.aisdestination')"
											:disabled="formDisabled"
											toplabel
											maxlength="200"
										></pui-text-field>
									</v-flex>
								</v-layout>
							</v-flex>
							<!-- AISSTATUS -->
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<pui-number-field
											:id="`aisstatus-logaiseventextended`"
											v-model="model.aisstatus"
											:label="$t('form.logaiseventextended.aisstatus')"
											:disabled="formDisabled"
											toplabel
											maxlength="10"
										></pui-number-field>
									</v-flex>
								</v-layout>
							</v-flex>
							<!-- AISSHIPTYPE -->
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<pui-number-field
											:id="`aisshiptype-logaiseventextended`"
											v-model="model.aisshiptype"
											:label="$t('form.logaiseventextended.aisshiptype')"
											:disabled="formDisabled"
											toplabel
											maxlength="10"
										></pui-number-field>
									</v-flex>
								</v-layout>
							</v-flex>
							<!-- AISMANEUVER -->
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<pui-number-field
											:id="`aismaneuver-logaiseventextended`"
											v-model="model.aismaneuver"
											:label="$t('form.logaiseventextended.aismaneuver')"
											:disabled="formDisabled"
											toplabel
											maxlength="10"
										></pui-number-field>
									</v-flex>
								</v-layout>
							</v-flex>
							<!-- AISDRAUGHT -->
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<pui-number-field
											:id="`aisdraught-logaiseventextended`"
											v-model="model.aisdraught"
											:label="$t('form.logaiseventextended.aisdraught')"
											:disabled="formDisabled"
											toplabel
											maxlength="8"
										></pui-number-field>
									</v-flex>
								</v-layout>
							</v-flex>
							<!-- AISEPFD -->
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<pui-number-field
											:id="`aisepfd-logaiseventextended`"
											v-model="model.aisepfd"
											:label="$t('form.logaiseventextended.aisepfd')"
											:disabled="formDisabled"
											toplabel
											maxlength="10"
										></pui-number-field>
									</v-flex>
								</v-layout>
							</v-flex>
							<!-- AISTOBOW -->
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<pui-number-field
											:id="`aistobow-logaiseventextended`"
											v-model="model.aistobow"
											:label="$t('form.logaiseventextended.aistobow')"
											:disabled="formDisabled"
											toplabel
											maxlength="10"
										></pui-number-field>
									</v-flex>
								</v-layout>
							</v-flex>
							<!-- AISTOSTERN -->
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<pui-number-field
											:id="`aistostern-logaiseventextended`"
											v-model="model.aistostern"
											:label="$t('form.logaiseventextended.aistostern')"
											:disabled="formDisabled"
											toplabel
											maxlength="10"
										></pui-number-field>
									</v-flex>
								</v-layout>
							</v-flex>
							<!-- AISTOPORT -->
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<pui-number-field
											:id="`aistoport-logaiseventextended`"
											v-model="model.aistoport"
											:label="$t('form.logaiseventextended.aistoport')"
											:disabled="formDisabled"
											toplabel
											maxlength="10"
										></pui-number-field>
									</v-flex>
								</v-layout>
							</v-flex>
							<!-- AISTOSTARBOARD -->
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<pui-number-field
											:id="`aistostarboard-logaiseventextended`"
											v-model="model.aistostarboard"
											:label="$t('form.logaiseventextended.aistostarboard')"
											:disabled="formDisabled"
											toplabel
											maxlength="10"
										></pui-number-field>
									</v-flex>
								</v-layout>
							</v-flex>
						</v-layout>
					</pui-field-set>
				</v-flex>
			</v-layout>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import LogaiseventextendedFormHeader from './LogaiseventextendedFormHeader.vue';

export default {
	name: 'logaiseventextended-form',
	mixins: [PuiFormMethodsMixin],
	components: { LogaiseventextendedFormHeader },
	data() {
		return {
			modelName: 'logaiseventextended'
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
		}
	},
	computed: {
		shipInfo() {
			return this.model ? this.model.vesselimo + ' - ' + this.model.vesselname : '';
		}
	},
	created() {}
};
</script>
